/** @jsx jsx */
import { Box, Button, Card, Container, Flex, Grid, Image, jsx } from "theme-ui";
import React, { useCallback, useState, useEffect } from "react";
import { Layout, Content, Section } from "maker-ui";
import { PageContentNonMemoized } from "gatsby-plugin-hfn-profile/components/PageContentNonMemoized";
import { navigate } from "gatsby";
import options from "../mark-ui/options";
import theme from "../mark-ui/theme";
import Seo from "../components/seo";
import bannerImg from "../../static/imgs/covid-banner-img.png";
import partneringImg from "../../static/imgs/covid-partnering-img.png";
import careServiceKitImg from "../../static/imgs/covid-care-service-kit-img.png";
import careServiceClinicalImg from "../../static/imgs/covid-care-service-clinical-img.png";
import careServiceVirusImg from "../../static/imgs/covid-virus-shape-img.png";
import careServiceWebinarImg from "../../static/imgs/covid-care-service-webinar-img.png";
import careServiceHelpLine from "../../static/imgs/covid-care-service-help-line-img.png";
import pressEasternMirror from "../../static/imgs/covid-press-Eastern Mirror.png";
import pressNPnews24 from "../../static/imgs/covid-press-NP news 24.png";
import pressOutlookTheNews from "../../static/imgs/covid-press-Outlook The News Scroll.png";
import pressTelangana from "../../static/imgs/covid-press-Telangana Today.png";
import pressTheNewIndianExpress from "../../static/imgs/covid-press-The New Indian Express.png";
import covidBannerLogo from "../../static/imgs/covid-banner-logo.png";
import faceBookLogoImg from "../../static/imgs/Facebook Icon.png";
import twitterLogoImg from "../../static/imgs/Twitter.png";
import whatsappLogoImg from "../../static/imgs/Whatsapp Icon.png";
import linkedInLogoImg from "../../static/imgs/Linkedin.png";
import careServiceFoodImg from "../../static/imgs/covid-care-service-food-img.png";
import careServiceMobileImg from "../../static/imgs/covid-care-service-mobile-img.png";

import HeaderComp from "../components/Header";
import CovidFooter from "../components/CovidFooter";
import { eventCallbackTriggers } from "../templates/wppage";

const pageContent =
  '<gatsby_donation amount="0" btntext="Contribute" donationid="14"' +
  'projectid="" btnbg="#A986AC" colortext="#000000" colorprimary="#A986AC"' +
  'colorsecondary="#A986AC" colorbackground="white" colormuted="#f6f6f6"' +
  'colorhighlight="#efeffe" titletext="Covid Relief Fund"/>';
const PageContent = React.memo(PageContentNonMemoized);

export default function CovidFund() {
  const [careService, selectedCareService] = useState("essentialKits");
  const [currentOrigin, setCurrentOrigin] = useState("");

  useEffect(() => {
    setCurrentOrigin(window.location);
  }, []);

  const pressRelease = [
    { imgSrc: pressNPnews24 },
    { imgSrc: pressOutlookTheNews },
    { imgSrc: pressEasternMirror },
    { imgSrc: pressTelangana },
    { imgSrc: pressTheNewIndianExpress },
  ];

  const socialIcons = [
    {
      imgSrc: faceBookLogoImg,
      link: `https://www.facebook.com/sharer.php?u=${currentOrigin.origin}/covid-relief-fund/`,
    },
    {
      imgSrc: twitterLogoImg,
      link: `https://twitter.com/intent/tweet?text=${currentOrigin.origin}/covid-relief-fund/`,
    },
    {
      imgSrc: whatsappLogoImg,
      link: `https://api.whatsapp.com/send?text=${currentOrigin.origin}/covid-relief-fund/`,
    },
    {
      imgSrc: linkedInLogoImg,
      link: `https://www.linkedin.com/sharing/share-offsite/?url=${currentOrigin.origin}/covid-relief-fund/`,
    },
  ];

  const eventCallback = useCallback(
    (event) => eventCallbackTriggers("covid-relief-fund", event),
    []
  );

  const renderCareServiceCardText = (service) => {
    switch (service) {
      case "foodService":
        return (
          <>
            <p>
              Our Heartfulness centers had quickly improvised `Food Drives` to
              affected groups, at an organizational level and in some cases at
              the Government`s request.
            </p>

            <p>
              More than a million meals and family ration packs have been fed
              and provided across India since April 2020.
            </p>

            <p>
              Beneficiaries included displaced migrants as well as laborers
              impacted most by the current crisis.
            </p>
          </>
        );

      case "essentialKits":
        return (
          <>
            <p>
              With partners identified to source in basic medical supplies
              Pan-India, in addition to procuring medical equipment from other
              parts of the world, Heartfulness plans to go a step forward to
              support and augment the efforts of the brave warriors in this
              battle against the pandemic.
            </p>

            <p>
              Continue to provide several critical products like face masks,
              gloves, cleaning and hygiene products as well as medicines.
            </p>

            <p>
              Procure essential medical equipment like oxygen concentrators from
              overseas and ambulances.
            </p>

            <p>
              Beneficiaries of this effort included volunteers, health workers,
              wage workers.
            </p>

            <p>Support to local government hospitals Pan-India.</p>
          </>
        );

      case "clinicalTest":
        return (
          <p>
            Along with our partnering organizations, we aim to protect the
            valuable lives of our frontline health workers, and build
            infrastructure capacity efficiently in hospitals and local
            communities, by procuring and supplying Mobile Kiosks and Fever
            Clinics, Disinfectant Ovens and Tunnels across India, through
            validated and tracked channels.
          </p>
        );

      default:
        return <></>;
    }
  };

  const renderBtn = (link) => (
    <Button
      onClick={() => navigate(link)}
      sx={{
        ":focus": { outline: "none" },
        ":hover": { opacity: 0.9 },
        mr: 3,
        textTransform: "capitalize",
        borderRadius: "42px",
        height: "45px",
        color: "white",
        backgroundColor: "#A986AC",
        margin: "13px",
      }}
    >
      CONTRIBUTE
    </Button>
  );

  const seoMeta = [
    {
      name: `twitter:url`,
      content: `${currentOrigin.origin}${currentOrigin.pathname}`,
    },
    {
      name: `og:url`,
      content: `${currentOrigin.origin}${currentOrigin.pathname}`,
    },
    {
      name: `og:image`,
      content: `${currentOrigin.origin}/imgs/care-for-India-share-img.png`,
    },
    {
      name: `twitter:image`,
      content: `${currentOrigin.origin}/imgs/care-for-India-share-img.png`,
    },
    {
      name: `og:image:width`,
      content: "1200",
    },
    {
      name: `og:image:height`,
      content: "630",
    },
    {
      name: `twitter:card`,
      content: `summary_large_image`,
    },
  ];

  return (
    <Layout theme={theme} options={options}>
      <Seo
        description="Heartfulness is sparing no effort in serving humanity in mitigating the COVID-19 distress by addressing shortages of essential medical supplies, food and medicines, pandemic stress, infrastructure support and online medical kit."
        meta={seoMeta}
        title="covid-relief-fund"
      />
      <HeaderComp />
      <Content
        sx={{
          color: "#000000",
        }}
      >
        <Flex
          sx={{
            display: ["none", "flex", null],
            position: "sticky",
            top: "30%",
            flexDirection: "column",
            float: "right",
            right: "1em",
          }}
        >
          {socialIcons.map((item) => (
            <Image
              key={item.link}
              onClick={() => {
                window.open(item.link);
              }}
              sx={{ cursor: "pointer", mb: 2 }}
              src={item.imgSrc}
            />
          ))}
        </Flex>
        <Section
          sx={{
            backgroundImage: ["none", null, `url(${bannerImg})`],
            backgroundPosition: "center",
            padding: ["1em", "4em 4em 10em 1em"],
            backgroundSize: "cover",
          }}
        >
          <Container sx={{ padding: ["0em 1em", "0em 4em"] }}>
            <Grid columns={[1, null, 2, "2fr 3fr"]}>
              <Box sx={{ textAlign: ["center", null, "left"] }}>
                <h2
                  sx={{
                    color: "#59355d",
                    fontSize: "45px",
                    fontWeight: "bolder",
                  }}
                >
                  Heartfulness
                </h2>
                <Image width="70%" src={covidBannerLogo} />
                <h6
                  sx={{
                    mt: 4,
                    mb: 4,
                    fontSize: ["12px", "25px"],
                    lineHeight: "1.5",
                    fontWeight: "bold",
                  }}
                >
                  Heartfulness General Fund <br />- COVID Relief Efforts
                </h6>
                <p>
                  Heartfulness is sparing no effort in serving humanity in
                  mitigating the COVID-19 distress by addressing shortages of
                  essential medical supplies, food and medicines, pandemic
                  stress, infrastructure support and online medical kit.
                </p>
                <p>
                  All of your donations would go towards efforts ongoing in
                  India, worst hit by the second wave of the Coronavirus.
                </p>
              </Box>
              <Box />
            </Grid>
          </Container>
        </Section>
        <Section
          id="donationPanel"
          sx={{
            background: [
              "none",
              null,
              `url(${careServiceVirusImg}) 0 0 no-repeat, linear-gradient( #e7eefb 50%, #f4f1fb 50%)`,
            ],
            backgroundPosition: "left top",
            padding: ["0em 1em 0em 1em", "0em 4em 0em 4em"],
          }}
        >
          <Container sx={{ padding: ["0em 1em 25px 1em", "0em 4em 25px 4em"] }}>
            <Grid
              bg="#FFFFFF"
              sx={{
                p: 4,
                textAlign: "center",
                borderRadius: "20px",
                boxShadow: "0px 10px 30px #93939329;",
              }}
              columns={[1, 2, 4]}
            >
              <Box
                sx={{
                  p: 3,
                  borderWidth: [0, null, "3.5px"],
                  borderRightStyle: "solid",
                  borderImage:
                    "linear-gradient(to bottom, #2A85D066, #2A85D0, #2A85D066)1 100%",
                }}
              >
                <h6 sx={{ fontWeight: "bolder", minHeight: "40px" }}>
                  International Donations
                </h6>
                {renderBtn("https://www.heartfulnessinstitute.org/donate")}
                <h6 sx={{ color: "#7D7D7D" }}>Donors residing outside India</h6>
              </Box>
              <Box
                sx={{
                  p: 3,
                  borderWidth: [0, null, "3.5px"],
                  borderRightStyle: "solid",
                  borderImage:
                    "linear-gradient(to bottom, #2A85D066, #2A85D0, #2A85D066)1 100%",
                }}
              >
                <h6 sx={{ fontWeight: "bolder", minHeight: "40px" }}>
                  India Donations
                </h6>
                <PageContent
                  eventCallback={eventCallback}
                  pageContent={pageContent}
                />
                <h6 sx={{ color: "#7D7D7D" }}>
                  All donations are tax-exempted as eligible under section 80G
                  of the Indian Income Tax Act, 1961
                </h6>
              </Box>
              <Box
                sx={{
                  p: 3,
                  borderWidth: [0, null, "3.5px"],
                  borderRightStyle: "solid",
                  borderImage:
                    "linear-gradient(to bottom, #2A85D066, #2A85D0, #2A85D066)1 100%",
                }}
              >
                <h6 sx={{ fontWeight: "bolder", minHeight: "40px" }}>
                  International Facebook Donations
                </h6>
                {renderBtn("https://www.facebook.com/donate/491801415326138/")}
                <h6 sx={{ color: "#7D7D7D" }}>
                  Facebook Fundraiser for Donors residing outside India
                </h6>
              </Box>
              <Box sx={{ p: 3 }}>
                <h6 sx={{ fontWeight: "bolder", minHeight: "40px" }}>
                  Europe Donations
                </h6>
                {renderBtn("https://www.one-euro-a-day.ch/en/#donation")}
                <h6 sx={{ color: "#7D7D7D" }}>Donations for Europe</h6>
              </Box>
            </Grid>
          </Container>
        </Section>
        <Section sx={{ padding: ["1em", "4em"], bg: "#F4F1FB" }}>
          <Container sx={{ padding: ["0em 1em", "0em 4em"] }}>
            <Grid gap={4} columns={[1, null, 2]}>
              <Box>
                <Image src={partneringImg} />
              </Box>
              <Box>
                <h2 sx={{ fontWeight: "bolder", color: "#A986AC" }}>
                  Compassionate hearts
                </h2>
                <h2 sx={{ mb: 3, fontWeight: "bold", lineHeight: "1.5" }}>
                  at Heartfulness and its partnering organizations
                </h2>
                <p sx={{ mb: 4 }}>
                  are engaging all resources to serve humanity in mitigating the
                  COVID-19. distress by addressing food shortages, pandemic
                  stress, infrastructural support and online care. All
                  contributions made towards this would be utilized for ongoing
                  efforts in India, worst hit by the second wave of the
                  Coronavirus.
                </p>
                <p sx={{ mb: 4 }}>
                  In addition to providing human services, the donations would
                  be used to procure essential equipment from across the world
                  to aid the services of the Medical professionals - Oxygen
                  Concentrators - Ambulance Vehicles - Disinfectant Ovens -
                  Mobile Testing Kiosks - Fever Clinics - Health and Hygiene
                  Kits.
                </p>
                <p sx={{ mb: 4 }}>
                  Volunteers are coordinating access to hygienically cooked
                  foods locally in various cities to those in isolation and
                  their families.
                </p>
              </Box>
            </Grid>
          </Container>
        </Section>
        <Section sx={{ padding: ["1em", "4em"] }}>
          <Container sx={{ padding: ["0em 1em", "0em 4em"] }}>
            <h2
              sx={{
                fontSize: ["25px", null, "2rem"],
                textAlign: "center",
                fontWeight: "bolder",
                mb: 3,
              }}
            >
              <span sx={{ color: "#A986AC" }}>COVID care services</span> by
              Heartfulness
            </h2>
            <p sx={{ mb: 4 }}>
              Backed by 4000+ doctors, medical professionals and 2000+
              volunteers already part of its community, Heartfulness is
              providing medical assistance to all those in need in India.
              Services have already started in all the states with several
              volunteers and Doctor communities, serving multiple regional
              languages. This service will slowly be extended to other
              organizations in a systematic manner to provide assistance to a
              wider audience. Heartfulness will also be releasing a mobile app
              which will allow anyone looking for medical help to get assistance
              from available doctors worldwide 24/7.
            </p>
            <p
              sx={{
                a: {
                  color: "white",
                  ":hover": {
                    opacity: "0.6",
                    textDecoration: "none",
                  },
                },
              }}
            >
              Other Doctors, coordinators and volunteers can offer their support
              by sending an email to
              <a
                sx={{ color: "#000 !important" }}
                href="mailto:covidcare@heartfulness.org"
              >
                covidcare@heartfulness.org
              </a>
              or calling our toll free in India at 1800-121-3492.
            </p>
            <Grid sx={{ mt: 5 }} gap={4} columns={[1, 2, null]}>
              <Box>
                <Grid>
                  <Box
                    sx={{
                      opacity: careService !== "essentialKits" && "0.6",
                      cursor: "pointer",
                      fontWeight: "bolder",
                    }}
                    onClick={() => selectedCareService("essentialKits")}
                  >
                    <Grid
                      columns={[2, "1fr 2fr"]}
                      sx={{ alignItems: "center" }}
                    >
                      <Box>
                        <Image src={careServiceKitImg} />
                      </Box>
                      <Box>
                        Importing and providing Essential Kits & Medical
                        Equipments
                      </Box>
                    </Grid>
                  </Box>
                  <Box
                    sx={{
                      opacity: careService !== "clinicalTest" && "0.6",
                      cursor: "pointer",
                      fontWeight: "bolder",
                    }}
                    onClick={() => selectedCareService("clinicalTest")}
                  >
                    <Grid
                      columns={[2, "1fr 2fr"]}
                      sx={{ alignItems: "center" }}
                    >
                      <Box>
                        <Image src={careServiceClinicalImg} />
                      </Box>
                      <Box>
                        Volunteers are coordinating access to hospital beds,
                        ICUs, Oxygen Concentrators and Essential supplies
                      </Box>
                    </Grid>
                  </Box>
                  <Box
                    sx={{
                      opacity: careService !== "foodService" && "0.6",
                      cursor: "pointer",
                      fontWeight: "bolder",
                    }}
                    onClick={() => selectedCareService("foodService")}
                  >
                    <Grid
                      columns={[2, "1fr 2fr"]}
                      sx={{ alignItems: "center" }}
                    >
                      <Box>
                        <Image src={careServiceFoodImg} />
                      </Box>
                      <Box>
                        Coordinating access to hygienic food to those in
                        isolation and their families
                      </Box>
                    </Grid>
                  </Box>
                </Grid>
              </Box>
              <Box
                sx={{
                  p: "40px",
                  background: `url(${careServiceVirusImg}) 0 0 no-repeat`,
                  backgroundPosition: "bottom right",
                  boxShadow: "0px 10px 30px #93939329;",
                }}
              >
                <Card>{renderCareServiceCardText(careService)}</Card>
              </Box>
            </Grid>
          </Container>
        </Section>
        <Section sx={{ padding: ["1em", "4em"], bg: "#F4F1FB" }}>
          <Container sx={{ padding: ["0em 1em", "0em 4em"] }}>
            <Grid sx={{ lineHeight: "25px" }} gap={4} columns={[1, 2, 3, null]}>
              <Box
                sx={{
                  borderBottom: "5px solid #A986AC",
                  boxShadow: "0px 10px 30px #93939329",
                }}
              >
                <Card p={3} sx={{ height: "100%", bg: "#FFFFFF" }}>
                  <Image src={careServiceHelpLine} />
                  <div
                    sx={{
                      mt: 3,
                      fontWeight: "bolder",
                      fontSize: "20px",
                      mb: 3,
                    }}
                  >
                    ‘Voice That Cares’ Helpline:
                  </div>
                  <div sx={{ mt: 2, fontSize: "15px" }}>
                    Recognizing that crisis-panic and prolonged isolation can
                    cause anxiety and reduce immunity, we have launched ‘Voice
                    That Cares’, a 24×7 Helpline to help alleviate pandemic
                    stress. The helpline can be accessed by dialing the
                    toll-free number: <b>1800 121 3492 Dhyana</b>. The helpline
                    is backed by several hundred certified Heartfulness
                    Trainers. Their job is to respond to public calls and help
                    relieve the emotional or mental distress of callers.
                  </div>
                </Card>
              </Box>
              <Box
                sx={{
                  borderBottom: "5px solid #A986AC",
                  boxShadow: "0px 10px 30px #93939329",
                }}
              >
                <Card p={3} sx={{ height: "100%", bg: "#FFFFFF" }}>
                  <Image src={careServiceWebinarImg} />
                  <div
                    sx={{
                      mt: 3,
                      fontWeight: "bolder",
                      fontSize: "20px",
                      mb: 3,
                    }}
                  >
                    Medical Programmes:
                  </div>
                  <div sx={{ mt: 2, fontSize: "15px" }}>
                    Noticing the need for precautionary and preventive
                    interventions in hygiene and lifestyle, our Heartfulness
                    Continuing Medical Education team is undertaking various
                    virtual awareness programmes. Our team of healthcare experts
                    are volunteering their time online, to provide psychological
                    counselling and informative webinars.
                  </div>
                </Card>
              </Box>
              <Box
                sx={{
                  borderBottom: "5px solid #A986AC",
                  boxShadow: "0px 10px 30px #93939329",
                }}
              >
                <Card p={3} sx={{ height: "100%", bg: "#FFFFFF" }}>
                  <Image src={careServiceMobileImg} />
                  <div
                    sx={{
                      mt: 3,
                      fontWeight: "bolder",
                      fontSize: "20px",
                      mb: 3,
                    }}
                  >
                    Ongoing Heartfulness Practices:
                  </div>
                  <div sx={{ mt: 2, fontSize: "15px" }}>
                    Our network of fourteen thousand trainers, under the
                    guidance of Daaji, are working with millions of
                    practitioners around the world to provide virtual training
                    programmes in the Heartfulness Practices. These include
                    programmes on Relaxation, Meditation, Mental Detoxing and
                    Rejuvenation, and Connecting Within. These sessions are
                    available 24×7 every day on <b>HeartsApp</b> or in other
                    online meeting platforms. <br />
                    <br />
                    Additionally during these times, Daaji has been conducting
                    live meditation sessions worldwide on Sundays – “Meditation
                    Made Simpler” to learn how to meditate.
                  </div>
                </Card>
              </Box>
            </Grid>
          </Container>
        </Section>
        <Section sx={{ padding: ["1em", "4em"], bg: "#FCFCFF" }}>
          <Container sx={{ padding: ["0em 1em", "0em 4em"] }}>
            <h2
              sx={{
                textAlign: "center",
                color: "#A986AC",
                fontWeight: "bold",
                mb: 4,
              }}
            >
              Press Releases
            </h2>
            <Grid columns={[2, 3, 5]}>
              {pressRelease.map((item) => {
                return (
                  <Flex
                    key={item.imgSrc}
                    sx={{
                      alignItems: "center",
                      justifyContent: "center",
                      height: "225px",
                      boxShadow: "0px 10px 30px #93939329;",
                    }}
                  >
                    <Image src={item.imgSrc} />
                  </Flex>
                );
              })}
            </Grid>
          </Container>
        </Section>
        <Section sx={{ padding: ["1em", "4em"] }}>
          <Flex sx={{ justifyContent: "center" }}>
            <Box
              sx={{
                borderRadius: "20px",
                color: "#FFFFFF",
                bg: "#A986AC",
                textAlign: "center",
                p: "2em 5em",
              }}
            >
              <div sx={{ fontSize: "15px" }}>
                For all queries and interests to contribute/initiate Covid
                relief initiatives, please write to
              </div>
              <div
                sx={{
                  a: {
                    color: "white",
                    ":hover": {
                      opacity: "0.6",
                      textDecoration: "none",
                    },
                  },
                  fontSize: ["15px", "35px", null],
                }}
              >
                <a href="mailto:covidcare@heartfulness.org">
                  covidcare@heartfulness.org
                </a>
              </div>
            </Box>
          </Flex>
        </Section>
      </Content>
      <CovidFooter />
    </Layout>
  );
}
